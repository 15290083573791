<mat-card id='view-assessment'>
    <mat-card-header>
      <span>Assessment </span>
      <span class="spacer"></span>
      <span>
        <button id="edit_view_button" mat-stroked-button color="primary" (click)="onClick()">Exit</button>
      </span>
    </mat-card-header>
    <mat-card-content class="assessments_container">
      <div id='assessment_container'>
        <div [ngClass]='"fade"' id="surveyElement"></div>
      </div>
    </mat-card-content>
  
  
    <mat-card-header *ngIf="isCompleted">
      <span> Assessment Complete </span>
      <span class="spacer"></span>
    </mat-card-header>
  
    <mat-card-content class="view_user-plan_container" *ngIf="isCompleted">
      <div class="card-row">
        <div class="infoSection">
          <div class="card-row">
            <div class="card-column">
              <p class="info">
                <span class="details_label">Completed on</span>
                {{updatedDate | date: 'dd MMM yyyy HH:mm' }}
              </p>
              <p class="info">
                <span class="details_label">Completed by</span>
                {{updatedBy}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  
    <mat-card-footer>
      <span class="spacer"></span>
    </mat-card-footer>
  </mat-card>