import { Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageOrganisationsService } from 'src/app/features/manage-organisations/manage-organisations.service';
import { ViewService } from 'src/app/features/manage-organisations/view-organisation/view.service';
import { CompleteAssessmentsService } from 'src/app/features/service-user/complete-assessments/complete-assessments.service';
import { EventEmitter } from 'stream';
import * as Survey from 'survey-angular';
import * as widgets from 'surveyjs-widgets';
import { AssessmentService } from '../../../assessment/assessment.service';
import { Location } from '@angular/common';
import { BaseUrl } from 'src/app/framework/constants/url-constants';

@Component({
  selector: 'app-psl-view-assessment',
  templateUrl: './psl-view-assessment.component.html',
  styleUrls: ['./psl-view-assessment.component.scss']
})
export class PslViewAssessmentComponent implements OnInit {

  json;
  surveyItem;
  assessmentTemplate;
  assessmentName;
  assessmentTemplateId: any;
  isCompleted: boolean = false;
  private score: number = 0;
  organisationId: any;
  orgLeadId;
  assessmentStatus;
  isAssessmentComplted:boolean;
  assessmentUserId;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly assessmentService: AssessmentService,
    private readonly router: Router,
    private viewService: ViewService,
    private readonly manageOrganisationsService:ManageOrganisationsService,
    private readonly location: Location,
  ) { }

  ngOnInit() {
    this.getAssessmentJson();
    this.viewService.organisationId.subscribe(id => 
      this.organisationId = id
    );
    this.viewService.assessmentContent.subscribe(result => {
      this.isAssessmentComplted = result;
    }
    );
    
  }

  renderAssessment(assessmentUserId) {
    this.onDownload(assessmentUserId);
    Survey.SurveyNG.render('surveyElement', {
      model: this.surveyItem,
    });

  }

  getAssessmentJson() {
    this.route.params.subscribe((params: any) => {
      this.assessmentTemplateId = params.id;
      this.assessmentUserId = params.unitId;
      this.assessmentService.getAssessment(params.id).subscribe((data: any) => {
        this.assessmentTemplate = data;
        this.assessmentName = this.assessmentTemplate.assessmentName;
        this.json = JSON.parse(this.assessmentTemplate.questionJson);
        this.surveyItem = new Survey.Model(this.json);
            if(this.isAssessmentComplted){
              this.assessmentService.getCompletedAssessment(params.assessmentTemplateUserId, this.assessmentTemplateId).subscribe(
                answerData => {
                  this.surveyItem.mode = 'display';
                  this.renderAssessment(this.assessmentUserId);
                  const answerJson = JSON.parse(answerData.answerJson);
                  this.surveyItem.data = answerJson;
                }
              )
            }
      },
        err => console.error(err),
        () => this.renderDummyAssessment()
      );
    });
  }

  renderDummyAssessment() {
    const defaultThemeColorsEditor = Survey
      .StylesManager
      .ThemeColors['default'];
    defaultThemeColorsEditor['$primary-color'] = '#2b769b';
    defaultThemeColorsEditor['$secondary-color'] = '#2b769b';
    defaultThemeColorsEditor['$main-color'] = '#2b769b';
    Survey.StylesManager.applyTheme();
    Survey.JsonObject.metaData.addProperty("itemvalue", { name: "score:number" });
    Survey.SurveyNG.render('surveyElement', {
      model: this.surveyItem
    });
  }

  verifySurveyResponse(result) {
    this.score = 0;
    if (!result.surveyItem.checkIsCurrentPageHasErrors()) {
      const scoreQuestion = result.surveyItem.getQuestionByName("score");
      if (scoreQuestion) {
        const keys = Object.keys(result.surveyItem.data);
        const pages = this.json.pages;
        pages.forEach(page => {
          const elements = page.elements;
          elements.forEach(question => {
            if (keys.includes(question.name) && result.surveyItem.getQuestionByName(question.name) !== null) {
              this.score += this.getScoreByQuestion(result, question);
            }
          });
        });
        scoreQuestion.value = this.score;
      }
    }
  }

  getScoreByQuestion(result: any, question: any) {
    const questionName = question.name;
    const elementType = result.surveyItem.getQuestionByName(questionName).getType();
    let count = 0;
    if (elementType === 'checkbox') {
      const itemValue = result.surveyItem.data[questionName];
      itemValue.forEach(checkBoxOptions => {
        count += this.getCount(question, checkBoxOptions);
      });
    } else if (elementType === 'bootstrapslider') {
      const itemValue = result.surveyItem.data[questionName];
      count = Number(itemValue);
    } else if (elementType !== 'expression' && elementType !== 'html' && elementType !== 'comment') {
      const itemValue = result.surveyItem.data[questionName];
      count = this.getCount(question, itemValue);
    }
    return count;
  }

  getCount(question, itemValue) {
    let count = 0;
    if (question.rateValues && question.type === 'rating') {
      question.rateValues.forEach(choice => {
        if (choice.value === itemValue && choice.score) {
          count = Number(choice.score);
        }
      });
    } else {
      if (question.choices) {
        question.choices.forEach(choice => {
          if (choice.score && choice.value === itemValue) {
            count = Number(choice.score);
          }
        });
      }
    }
    return count;
  }

  onClick(){
    this.location.back();
  }	
  
  onDownload(assessmentUserId) {
    this.surveyItem.onDownloadFile.add(function (survey, options) {
      const docName= 'document/PSL/Organization';
      const filePath = docName+'/'+assessmentUserId+"/DocLibrary/"+options.fileValue.name;
      const href =`${BaseUrl.ASSESSMENT}/psl/document/download`;
      var xhr = new XMLHttpRequest();
      xhr.open('POST', href, true);
      xhr.setRequestHeader("X-Authorization", 'Bearer ' + localStorage.getItem('token'));
      xhr.setRequestHeader("Content-Type","application/json");
      xhr.onloadstart = function (ev) {
        xhr.responseType = "blob";
      }
      xhr.onload = function () {
        var file;
        if (detectIEOrEdge()) {
          file = new Blob([xhr.response],  { type: options.fileValue.type });
        }
        else {
          file = new File([xhr.response], options.fileValue.name, { type: options.fileValue.type });
        }
        var reader = new FileReader();
        reader.onload = function (e) {
          options.callback("success", e.target.result);
        };
        reader.readAsDataURL(file);
      };
      xhr.send(filePath);
      
    });
  }

}

function detectIEOrEdge() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  var trident = ua.indexOf("Trident/");
  var edge = ua.indexOf("Edge/");
  return edge > 0 || trident > 0 || msie > 0;
}

function updatePreview(newValue, container, options) {
  container.innerHTML = "";
  (newValue || []).forEach(function (fileItem) {
    var button = document.createElement("div");
    button.className = "btn sv-btn sv-file__choose-btn sd-btn sd-btn--action";
    button.innerText = "Download " + fileItem.name;
    button.onclick = function () {
      alert(JSON.stringify(fileItem));
      downloadFile(fileItem, options);
      //createAndSubmitForm(fileItem);
    }
    container.appendChild(button);
  });
}

function downloadFile(fileValue, container) {
  var xhr = new XMLHttpRequest();
  xhr.open(
    "GET",
    "http://localhost:8080/file-upload-api/downloadFile?filePath=" + encodeURIComponent(fileValue.content)
  );

  xhr.onloadstart = function (ev) {
    xhr.responseType = "blob";
  }
  xhr.onload = function () {
    var file;
    if (detectIEOrEdge()) {
      file = new Blob([xhr.response], { type: fileValue.type });
    }
    else {
      file = new File([xhr.response], fileValue.name, { type: fileValue.type });
    }
    var reader = new FileReader();
    reader.addEventListener("load", function (e) {
      var downloadLink = document.createElement("a");
      downloadLink.href =""+e.target.result;
      downloadLink.download = fileValue.name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
    reader.readAsDataURL(file);
  };
  xhr.send();
}
