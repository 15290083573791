import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router,
         RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Utility } from '../utils/utility';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';
import { CanDeactivateComponent } from './can-deactivate/can-deactivate.interface';

@Injectable()
export class RegistrationGuard implements CanActivate,  CanDeactivateGuard {

    constructor(
        private readonly router: Router,
    ) { }
   
   
    canDeactivate(component: CanDeactivateComponent , nextState?: RouterStateSnapshot): boolean {
        const status = localStorage.getItem('isRegistrationCompleted');
        if(Utility.isEmpty(status)){
            return true;
        }
        const token = localStorage.getItem('token'); 
            if(!Utility.isEmpty(token) && (localStorage.getItem('isRegistrationCompleted') === 'true')) {
                return true;
            } else if(!Utility.isEmpty(token) && (localStorage.getItem('isRegistrationCompleted') === 'false')) {
                return false;
            }
            return false;
    }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean { 
        const token = localStorage.getItem('token'); 
        if(!Utility.isEmpty(token) && (localStorage.getItem('isRegistrationCompleted') === 'false')) {
            return true;
        } else if(!Utility.isEmpty(token) && (localStorage.getItem('isRegistrationCompleted') === 'true')) {
            return false;
        }
        return false;
    }

}
