import { DataSource } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewService } from '../../manage-organisations/view-organisation/view.service';

@Component({
  selector: 'app-psl-assessment',
  templateUrl: './psl-assessment.component.html',
  styleUrls: ['./psl-assessment.component.scss']
})
export class PslAssessmentComponent implements OnInit {

  displayedColumns: string[] = ['assessmentName', 'createdDate', 'modifiedDate', 'status', 'actions'];
  allAssessments: any[] = [];
  @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
  isContentAvailable: boolean = true;
  userId: any;
  organisationId:any;
  id:any;
  @Input() unitId: any;


  @Input() data: any;
  
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private viewService: ViewService,
  ) { }

  ngOnInit(): void {
    this.resolveOrganisationId();
  }

  resolveOrganisationId() {
    this.route.parent.params.subscribe((params: any) => {
      this.organisationId = params.id;
    });
  }

  onClickButton(id,assessmentTemplateUserId){
    this.id = id;
   var isPartiallyCompleted:boolean = false;
    if(this.data[0].isCompleted  || this.data[0].isPartiallyCompleted){
      isPartiallyCompleted = true;
    }
    this.viewService.assessmentStauts(isPartiallyCompleted);
    this.router.navigate(['./manage-organisations/view-psl-assessment/',id,assessmentTemplateUserId,this.unitId]);
  }

}
