import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageOrganisationsService } from '../manage-organisations/manage-organisations.service';
import { CompleteAssessmentsService } from '../service-user/complete-assessments/complete-assessments.service';
import { MaterialModule } from 'src/app/framework/material/material.module';
import { PslAssessmentComponent } from './psl-assessment/psl-assessment.component';
import { RouterModule } from '@angular/router';
import { AssessmentService } from '../assessment/assessment.service';
import { MatCardModule } from '@angular/material/card';
import { PslViewAssessmentComponent } from './psl-assessment/psl-view-assessment/psl-view-assessment.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    MatCardModule
  ],
  declarations: [
    PslAssessmentComponent,
   PslViewAssessmentComponent
   
  ],
  exports:[PslAssessmentComponent],
  providers: [
    ManageOrganisationsService,AssessmentService,
    CompleteAssessmentsService
  ]
})
export class PSLAssessmentModule { }
