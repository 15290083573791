import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  private content = new BehaviorSubject<any>(null);
  private assessmentCompltedContent = new BehaviorSubject<boolean>(null);
  
  public organisationId = this.content.asObservable();
  public assessmentContent = this.assessmentCompltedContent.asObservable();
  constructor() { }

  getOrgId(data){
    this.content.next(data);
  }

  assessmentStauts(data){
    this.assessmentCompltedContent.next(data);
  }
}
