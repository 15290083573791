import { Injectable, NgModule } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HTTP_INTERCEPTORS,
    HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';

import { SnackBarService } from './snack-bar.service';
import { ValidatorService } from '../components/form-control-messages/validator.service';
import { SessionsService } from '../../sessions/sessions.service';

@Injectable({
    providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(
        public router: Router,
        public sessionsService: SessionsService,
        public snackBar: SnackBarService,
        private readonly validatorService: ValidatorService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        request = request.clone({
            headers: request.headers.set('X-Authorization', 'Bearer ' + token)
                .set('Content-Type', 'application/json')
        });
        return next.handle(request).pipe(
            tap(
                (response: HttpEvent<any>) => {
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        console.log(err)
                        this.handle401Request(err);
                        this.handler500And400Request(err);
                        this.handle422Request(err);
                    }
                }
            )
        );
    }
    private handle422Request(err: HttpErrorResponse) {
        if (err.status === 422) {
            this.validatorService.setformSererValidator(err.error.errors);
        }
    }

    private handler500And400Request(err: HttpErrorResponse) {
        if (err.status === 500 || err.status === 400) {
            const error = err.error.errorMessage || err.error.applicationMessage;
            if (error !== 'No Plan has been created.') {
                this.snackBar.showCustomSnackBar(error, 'Dismiss', { duration: 6000 });
            }
        }
    }
    private handle401Request(err: HttpErrorResponse) {
        if (err.status === 401) {
            const error = err.error.applicationMessage || 'UnAuthorized User';
            this.sessionsService.signout().subscribe();
            this.snackBar.showCustomSnackBar(error, 'Dismiss', { duration: 6000 });
        }
    }
}

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        }
    ]
})
export class HttpRequestInterceptorModule { }