import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from './../../framework/constants/url-constants';

@Injectable()
export class ManageOrganisationsService {

  constructor(private readonly http: HttpClient) { }

  findAllPaginated(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.AUTHENTICATE}/psl/search/organisation`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getOrganisationById(id): Observable<any> {
    const href = `${BaseUrl.AUTHENTICATE}/manageOrganization/${id}`;
    return this.http.get<any>(href);
  }

  createOrganisation(payload) {
    const href = `${BaseUrl.AUTHENTICATE}/manageOrganization/psl/create`;
    return this.http.post<any>(href, payload);
  }

  updateOrganisation(payload) {
    const href = `${BaseUrl.AUTHENTICATE}/manageOrganization`;
    return this.http.put<any>(href, payload);
  }

  getTrust(): Observable<any> {
    const href = `${BaseUrl.AUTHENTICATE}/manageOrganization/psl/trust`;
    return this.http.get<any>(href);
  }

  getUserByTrustId(id): Observable<any> {
    const href = `${BaseUrl.USER}/psl/${id}`;
    return this.http.get<any>(href);
  }

  getAssessmentByUser(id): Observable<any>{
    return this.http.get<any>(`${BaseUrl.ASSESSMENT}/getAssessmentListByUser/${id}`);
  }

  getInvoiceDetails(orgId) {
    const href = `${BaseUrl.USER}/psl/invoiceDetails/${orgId}`;
    return this.http.get<any>(href);
  }


  downloadFile(filePath): Observable<any>{
    const href =`${BaseUrl.USER}/document/psl-invoice`;
    return this.http.post(href, filePath,{responseType: 'blob',observe: 'response'});
  }
  downloadDoc(filePath): Observable<any>{
    const href =`${BaseUrl.ASSESSMENT}/psl/document/download`;
    return this.http.post(href, filePath,{responseType: 'blob',observe: 'response'});
  }

  getOrgUnitsByOrgId(id): Observable<any>{
    return this.http.get<any>(`${BaseUrl.USER}/psl/units/${id}`);
  }

  getOrganisations(): Observable<any>{
    return this.http.get<any>(`${BaseUrl.AUTHENTICATE}/psl/organisation`);
  }

  getClientOrganizations() {
    const href = `${BaseUrl.USER}/user/organizations`;
    return this.http.get<any>(href);
  }

  findOrganizationByUnitTrustId(trustUnitId) {
    const href = `${BaseUrl.USER}/psl/findOrgDettails/${trustUnitId}`;
    return this.http.get<any>(href);
  }


}
