export const environment = {
  production: true,
  apiURL: 'https://devservices.sequation.net',
  logo: `./assets/logo/login_logo.png`,
  logo1: `./assets/logo/other_logo.png`,
  appTitle: 'PSL',
  appInsights: {
      instrumentationKey: '',
      disableExceptionTracking: true
  },
  azureBlobStorage: 'https://sequationdevsav2.blob.core.windows.net',
  fileSizeLimit: '1GB',
}
