<div class="grid-row nomargin">
    <mat-card id='assessments'>
      <mat-card-header>
        <span> Assessments </span>
        <span class="spacer"></span>
      </mat-card-header>
      <mat-card-content class="assessments_container">
  
        <mat-table id="assessment_table" #table [dataSource]="data" class="isMobile">
         
         <ng-container matColumnDef="assessmentName">
            <mat-header-cell *matHeaderCellDef id="template_name_button" class="assessmentName width-40">
              Assessments
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="assessmentName width-40"><span class="mobile-label">Assessments: </span>
              {{element.assessmentName}}
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="createdDate">
            <mat-header-cell class="width-15" *matHeaderCellDef id="date_created_button"> Date Assigned
            </mat-header-cell>
            <mat-cell class="width-15" *matCellDef="let element"><span class="mobile-label">Date Assigned: </span>
              {{element.createdDate | date:'dd MMM yyyy' }} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="modifiedDate">
            <mat-header-cell class="width-15" *matHeaderCellDef id="date_created_button"> Last Updated
            </mat-header-cell>
            <mat-cell class="width-15" *matCellDef="let element"><span class="mobile-label">Last Updated: </span>
              {{element.updatedDate | date:'dd MMM yyyy' }}
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="status">
            <mat-header-cell class="width-15" *matHeaderCellDef id="date_created_button"> Status
            </mat-header-cell>
            <mat-cell class="width-15" *matCellDef="let element"><span class="mobile-label call-status-label">Status: </span>
              <span *ngIf="element.isCompleted" class="status">COMPLETED</span>
              <span *ngIf="!element.isCompleted && (element.isPartiallyCompleted || element.updatedDate)" class="status">IN PROGRESS</span>
              <span *ngIf="!element.isCompleted && !element.isPartiallyCompleted && !element.updatedDate" class="status">NOT STARTED</span>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="actions-column">Action</mat-header-cell>
            <mat-cell *matCellDef="let element" class="actions-column"> <span class="mobile-label"></span>
  
              <button id="view_assessment_button_{{element.assessmentTemplateUserId}}" 
                mat-icon-button color="primary" (click)="onClickButton(element.assessmentTemplateId,element.assessmentTemplateUserId)"
                [queryParams]="{ id : userId,  fullName:fname, assessmentTemplateId: element.assessmentTemplateId, assessmentTemplateUserId: element.assessmentTemplateUserId, isCompleted: element.isCompleted, isPartiallyCompleted: element.isPartiallyCompleted, status: status }"
                queryParamsHandling="merge" title="View Entire Assessment" attr.aria-label="View {{element.assessmentName}} ">
                <mat-icon>visibility</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
  
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div class="display_none" *ngIf="!isContentAvailable">
          <span> There is currently nothing here </span>
      </div>
  
        <mat-paginator id="assessments_pagination" [showFirstLastButtons]='true' [hidePageSize]="true" >
        </mat-paginator>
      </mat-card-content>
  
    </mat-card>